:root {
    --primary-color: #c1121f;
    --secondary-color: #153448;
}
.item {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    height: 250px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    width: 500px;
}
    .item img {
       
        align-self:center;
        height:100%;
       width:300px !important;
       padding:2px
    }
    .item .desct-item {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        width:50%;
    }

.item h2 {
    font-size: 17px;
    font-weight: 700;
    width: 100%;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: #31304D
}
.item .txt {
    width: 100%;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size:14.6px ;
    height:fit-content;

}
    .item .product-info {
        color: var( --secondary-color);
        padding: 5px;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 1px;
        transition: all 0.3s ease;
        color: #C7C8CC;
        float:right;
        margin-top:52px
    }
.item .product-info i{
    font-size:20px;
    font-weight:bold;
}
.item .product-config {
    color: var(--primary-color);
    border: 1px solid var(--primary-color);
    text-align: center;
    padding: 5px;
    transition: all 0.1s ease;
}
 .item .product-config i{
     margin-right :10px
 }
.item .product-info:hover {
    gap: 10px;
    color: #3D3B40
}
.item .product-config:hover {
    background-color: var(--primary-color);
    color:whitesmoke
}
.div-price {
    font-size: 20px;
    font-weight: 600;
}
    .div-price span {
        font-size: 24px;
        color: black;
        font-weight: bold;
        color: var(--primary-color);
    }
@media screen and (max-width: 516px) {
    .item {
        height: 270px;
        width:100%
    }
}
@media screen and (max-width: 425px) {
    .item {
        flex-direction: column;
        height: fit-content !important;
        justify-content:center
    }
    .item img{
        height:250px;
        width:100%;
        object-fit:cover
    }
    .item .desct-item{
        width:100%
    }
    
}
