body{
    position:relative
}
:root {
    --primary-color: #c1121f;
}
 a{
     text-decoration:none
 }
a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
    background-color:rebeccapurple
}

.my_header {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
   position:sticky;
   top:0 ;
   background-color:white;
   z-index:2000;
}

.logo{
   width:110px ;
   height:90px;
   object-fit:cover
}



.search {
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
    border: 1px solid #e9ecef;
    border-radius:1px
}

    .search input {
        height: 50px;
        text-indent: 20px;
        font-size: 15px;
        border: none ;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }


        .search input:focus {
            box-shadow: none;
            border: 1px solid #dee2e6;
            border-radius: 0 ;
        }



    .search button {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 50px;
        border-radius: 0;
        
       
    }
.search button i{
}
/*Contact-info*/
.waren-contact {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content:center ;
 
}
.Contact-info, .phone, .email {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    margin-left: 20px;
}
.Contact-info{
    width:fit-content;
    padding:5px  30px;
  
   
}
.navbare {
    position: sticky;
    top: 10px;
}
.myContainer {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    width: 80%;
    margin: auto;
    justify-content: space-between;
}
.cart_log {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding:10px 10px 0 0 
}


.phone {
    gap: 10px
}
/* Germany flag */
.number img {
    width: 20px;
}
.number {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px
}
.number span{
    width:fit-content;
    font-size:14px;
    white-space:nowrap
}
.email i , .phone i {
    border: 1px solid var(--primary-color);
    padding: 7px;
    border-radius: 50%;
    color: var(--primary-color)
}
/*Navslink*/



.shop_cart a {
    border: 1px solid;
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    padding: 13px;
    color: var(--primary-color);
}
    .shop_cart a:hover {
        background-color: #fef4f5;
    }
.shop_cart i{
    font-size:1.6rem
}

.profile a {
    display: flex;
    gap: 10px;
    flex-direction: row;
    align-items: center;
    padding: 13px;
    background-color: black;
    color: whitesmoke;
    height:100%
}

.cart_log2{
    padding-right:20px;
    display:none;
    
}
.side {
    display: flex ;
    position: fixed;
    top: 100px;
    right: 0;
    bottom: 0;
    width: 100%;
    left: 0;
    flex-direction: column;
    justify-content: end;
    transition :all 0.5s ease-in-out ;
   

}
    .side.active{
display :none;
    }
.Navsidebare {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100%;
    background-color: #f2f2f2;
    padding: 20px;
    box-sizing: border-box;
    align-items: center;
    align-self: center;
    gap: 20px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

    .Navsidebare a {
        color: black;
        padding: 16px 30px;
        display: inline-block;
        position: relative;
        width: 100%;
        text-align: center;
        font-weight: 600
    }
.hamburge{
       padding:30px;
       position:absolute;
       right:20px;
       display:none ;
       cursor: pointer;
}
.hamburger-lines {
    display: block;
    height: 26px;
    width: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

    .hamburger-lines .line {
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #0e2431;
    }

    .hamburger-lines .line1 {
        transform-origin: 0% 0%;
        transition: transform 0.4s ease-in-out;
    }

    .hamburger-lines .line2 {
        transition: transform 0.2s ease-in-out;
    }

    .hamburger-lines .line3 {
        transform-origin: 0% 100%;
        transition: transform 0.4s ease-in-out;
    }
.hamburge.active  .hamburger-lines .line1 {
    transform: rotate(45deg);
}
.hamburge.active .hamburger-lines .line2 {
    transform: scaleY(0);
}

.hamburge.active .hamburger-lines .line3 {
    transform: rotate(-45deg);
}

.Navslink {
    display: flex;
    flex-direction: row;
    gap: 10px
}

    .Navslink a {
        color: black;
        padding: 10px 15px;
        display: inline-block;
        position: relative;
        font-weight:600
    }

        .Navslink a:after, .Navsidebare a:after {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: var(--primary-color);
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
        }

        .Navslink a:before, .Navsidebare a:before {
            content: "";
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            top: 0;
            left: 0;
            background-color: var(--primary-color);
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
        }

        .Navslink a:hover:after, .Navslink a:hover:before, .Navsidebare a:hover:after, .Navsidebare a:hover:before {
            transform: scaleX(1);
            transform-origin: bottom left;
        }

.Contact-info.hide{
    opacity:0;
    display :none;
}
/*responcive*/
@media screen and (max-width: 1497px) {
    .containcontain {
        padding: 0 10px;
      
            
    }
    .myContainer {
        width:100%
    }
    
          
    
}
@media screen and (max-width: 1212px) {
    

    .contain {
        padding: 0;
    }
    
}

@media screen and (max-width: 1055px) {
    
    .Navslink{
        display:none;
    }
    .hamburge{
        display:block;
    }
}
@media screen and (max-width: 489px) {

    .Contact-info{
        flex-direction: column;
        align-self: center;
        gap: 5px;
    }
    .waren-contact{
        justify-content: center;
        
    }
    .waren-contact .bxs-envelope{
        margin-right: 17px;
    }
    
}
@media screen and (min-width: 1055px) {

    .side {
        display: none
    }
}













