.footer {
    background-color: #070F2B;
    width: 100vw;
    padding-top: 10px;
}
.footer a {
        transition:all 0.2s ease;
        color :white
}
    .footer section a:hover {
        color: #c1121f !important
    }

    @media screen and (max-width: 446px) {

        .err{
            background-color:red;
            width: 40px;
        }
    }