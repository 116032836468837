
.mycards{
    color:black;
    margin:20px
}
.mycards a{
    color :black
}
.mycards .mycard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 230px;
}
.card_img {
    height: 180px;
     display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px ;

}
.card_img img {     
        object-fit: contain;
        height:100% ;
        border :2px solid white 
}
.mycard .desc {
    width:100%;
    display:flex;
    flex-direction:column;
    align-items:center
}
.slick-slide > div {
   margin:0 10px
}
.desc .titel {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 80%;   
    
}
.desc .price {
    font-weight: bold  
}
