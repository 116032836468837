.main-div{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
margin-top: 20px;
}
.main-div img{
    width: 600px;
    height: 500px;
    object-fit: contain;
}
@media screen and (max-width: 622px) {
    .main-div img{
        width:90% 
    }
}
@media screen and (max-width: 590px) {
    .main-div img{
object-fit: cover;
width: 300px;
    }
}