.ProductSection {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    width:100%;
    row-gap:40px
}
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
}
.sortierung {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    background-color: #F3F3F3;
    border: 1px solid #B6C4B6;
    justify-content:end;
    width:80%;
    align-self:center
}
.sortierung .form-select{
    width:220px
}
.form-select:focus {
    box-shadow: none !important;
    border: 1px solid #0C359E !important
}
.sortierung label {
    font-weight: 700;
    color: #3D3B40
}

@media screen and (max-width: 396px) {
    .sortierung{
        width:100%
    }

       
}
 