
.div_main_img{
    width:100%;
    height:400px;
    position:relative
}
.div_main_img img {
    width: 100%;
    height:100%;
    object-fit:cover
}
.main-img-desc {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background: rgba(143, 140, 140, 0.38);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3.9px);
    -webkit-backdrop-filter: blur(3.9px);
    color: white;
    align-items: center
}
    .main-img-desc .titel{
        font-size:2.6rem;
        font-weight:bold
    }
    .main-img-desc div {
        display: flex;
        flex-direction: column
    }
        .main-img-desc div span {
            font-size: 1.8rem;
           
        }
.div_main_desc {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    gap: 13px;
    margin-top: 10px;
    padding: 20px;
}
    .div_main_desc .text-main {
        display: flex;
        flex-direction: column;
        gap: 13px;
        width:70%
    }
.div_main_desc .text-main span{
    font-size:15px
}
    .div_main_desc .list-product {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .div_main_desc .list-product h5 {
    }
    .div_main_desc .list {
        display: flex;
        flex-direction: column;
        height:100%;
        justify-content:space-around
    }
        .div_main_desc .list span {
            display: flex;
            align-items: center;
            gap: 5px;
            font-weight: 600;
            font-size: 15px
        }
    .div_main_desc i {
        color: #41B06E
    }
.CardSlider{
    margin-top:40px
}
.CardSlider h5 {
    text-align: center;
    font-weight: bold;
    font-size: 1.4rem
}
.project-imgs {
    background-color: #070F2B;
    position: relative;
    height: 650px;

}
    .project-imgs .imges-div {
        display: flex;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        gap: 10px;
        width:100%;
       padding:19px
    }
    .project-imgs .imges-div div {
        width: 250px;
        height:300px;
        background-color:black;
        overflow:hidden;
    }
.project-imgs .imges-div img{
       width:100%;
       height:100%;
       object-fit:cover
}
.sid1{
    position:absolute;
    top:0;
    left:0
}
.sid2 {
    position: absolute;
    bottom: 0;
    left: 0
}
.to-imges {
    color: whitesmoke;
    border: 1px solid whitesmoke;
    padding: 10px;
    position: absolute;
    top: 40px;
    left: 20px;
    transition: all 0.1s ease;
    font-weight: bold;
}
    .to-imges:hover {
        color: whitesmoke;
        font-weight: bold;
        border-color: #070F2B
    }
.bewertung-kunden{
    display:flex;
    align-items:center;
    gap:10px;
    width:100%;
    margin:20px 0 ;
    position:relative
}
.popup {
    position: absolute;
    z-index: 1000;
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(0.8px);
    -webkit-backdrop-filter: blur(0.8px);
    height: 100%;
    left: 20px;
}
    .popup.schow {
        display: flex
    }
    .popup .Bewertung-div {
        width: 50%;
        cursor: pointer;
        height:fit-content;
        background-color:white
    }
        .popup .Bewertung-div .bx-x{
            font-size:25px;
            cursor:pointer
}
        .popup .Bewertung-div a {
            width: 100%;
            color: #0E46A3;
            display: flex;
            align-items: center;
            gap:5px
        }
        .popup .Bewertung-div a:hover {
            text-decoration:underline #0E46A3
        }
        .bewertung-kunden a {
            color: black;
            width: 25%;
        }
.Bewertung-div {
    border: 1px solid #DDDDDD;
    padding: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 200px;
    border-radius:10px
}
    .Bewertung-div:hover {
        border-color: #0E46A3;
        background-color: #f0f5fe
    }
.popup .Bewertung-div:hover {
    border-color: #DDDDDD;
    background-color: white
}
.start-mark {
        display: flex;
        align-items: center;
        justify-content: space-between;
}
.start-mark .bxs-badge-check {
        color: #0E46A3;
        font-size:1.2rem
}
.bewertung-kunden .bxs-star, .bewertung-kunden .bx-star {
    color: #FFC100;
    font-size: 1.2rem
}

.datum-bewertung {
    color: #222831
}
.bewertung-kunden h6 {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bewerungd-text {
    width: 100%;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.Bewertung-div .mehr-lesen {
    color: #0E46A3;
}
.sevice-div{
    height:200px;
    display:flex;
    align-items:end;
    gap:30px;
    position:relative;
}
.sevice-div img{
        position:absolute;
        left:0;
        width:250px;
        height:250px ;
        object-fit:contain
    }
    .sevice-div div {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 20px;
        padding-left: 250px;
        background-color: #0E46A3;
        height: 60%
    }
        .sevice-div div span {
            display: flex;
            flex-direction: column;
            font-weight: 600;
            font-size: 1.4rem;
            color: whitesmoke
        }
.sevice-div div span .bold{
      font-weight:bold
}
.servis-add {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 40px;
    margin-bottom: 60px;
}
.servis-add h3{
    text-align:center;
    font-weight:bold;
}
.servis-add .span1{
    text-align-last: center;
    font-size:1.04rem
}
    .servis-add .span2 {
        font-weight: 600;
        color: #114232;
        display: flex;
        align-items: center;
        justify-content: center
    }
@media screen and (max-width: 390px) {
    .main-img-desc{
        width:300px 
    }
}
@media screen and (max-width: 771px) {
    .bewertung-kunden {
        flex-wrap:wrap;
       justify-content:center
    }
        .bewertung-kunden a {
            width: 200px
        }
    .popup {
        left: 0px;
    }
}

@media screen and (max-width: 996px) {


    .div_main_desc {
       flex-direction:column;
    }
    .div_main_desc .text-main {
        width: 100%;
    }
    .list-product {
        width: 100%;
    }
}
@media screen and (max-width: 1203px) {
    .sevice-div div span{
        font-size:1rem;
        
    }
}
@media screen and (max-width: 768px) {
    .sevice-div div span {
        font-size: 1rem;
    }
    .sevice-div img {
        width: 180px;
        height: 180px;
        left:-40px
    }
    .sevice-div div{
        padding-left:100px
    }
    
}
@media screen and (max-width: 492px) {
    .sevice-div div .span-hide {
        display: none;
    }
    .popup .Bewertung-div{
        width:100%
    }
    .popup {
        left: 0px;
    }
}