:root {
    --primary-color: #c1121f;
    --secondary-color: #153448;
}
.cart-desc {
    margin: 10px 0;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
    padding:10px;
    width:100%
}
.product-info-desc {
    display: flex;
    gap: 10px;
    width:100%;
}

.des-prise{
    width:35%;
}
.img-galarie {
    display: flex;
    height: 480px;
    padding: 10px;
    gap: 10px;
    width: 100%
}

.side-img {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: center;
    gap: 10px;
    padding: 10px;
    overflow-y:scroll
}
    .side-img::-webkit-scrollbar {
        width: 4px;
        height: 41px;
    }

    .side-img::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
    }

    .side-img::-webkit-scrollbar-thumb {
        background: #e6e6e6;
        border: 0px none #ffffff;
        border-radius: 0px;
    }

        .side-img::-webkit-scrollbar-thumb:hover {
            background: #B4B4B8;
        }

        

    .side-img::-webkit-scrollbar-track {
        background: #ffffff;
        border: 0px none #ffffff;
        border-radius: 0px;
    }

       


    .side-img::-webkit-scrollbar-corner {
        background: transparent;
    }
.main_img {
   width:100%;
   height:100%;
   display:flex;
   align-content:center;
   justify-content:center
       
}

.main_img img{
    width:100%;
    height:100%;
    object-fit:contain;
        
}
    .side-img img {
        width: 100% ;
        object-fit: contain;
        height: 25%;
        cursor: pointer;
    }
.secend-info {
    display: flex;
    font-size: 14px;
    font-weight: 600;
    color: #45474B;
    width:444px;
    gap:20px
}
    .secend-info > :first-child i {
        color: #1679AB
    }
    .secend-info > :last-child i {
        color: #41B06E
    }
.discription {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width:100%
}
.cart-desc h2 {
    font-size: 1.5rem;
    font-weight:700;
    padding-bottom:15px
}
.beschreibung {
        display: flex;
        flex-direction: column;
        gap: 10px ;
        margin:40px 0
}
.beschreibung h6 {
        font-size: 1.7rem;


 }
    .beschreibung p:last-child {
    }
   
.configuration {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 10px;
    background-color: rgb(204, 204, 204,0.1)
}
.cart-desc .div-price {
    font-size: 22px;
    font-weight: bold
}
    .cart-desc .div-price .price {
        font-size: 2rem;
        color: var( --primary-color)
    }
.des-prise a {
    color: #F6F5F2;
    border: 1px solid var(--primary-color);
    padding: 10px;
    background-color: var(--primary-color);
    transition: all 0.1s ease;
    width: 100%;
    font-weight: 600;
    display:flex;
    align-items:center;
    justify-content:center;
    gap:5px
}
.des-prise .product-config:hover {
    background-color: #e71625;
    border: 1px solid #e71625;
}
.product-config i{
    font-size:1.2rem;
    font-weight:bold
}
.des-prise {
    display: flex;
    flex-direction: row
}
.configuration a i {
    margin-right: 6px
}
/*configform*/
.config-form {
    border: 1px solid #DDDDDD;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
    .config-form .option {
        display: flex;
         gap:5px;
        flex-direction: column;
        padding:5px
    }
        .config-form .option .div {
            display: flex;
            gap:90px
        }
        .config-form .option .div-farbe {
            width: 70px;
            height: 70px;
            border: 1px solid gray;
            padding: 5px;
            cursor: pointer
        }
.option .div-farbe img{
    width:100%;
    height:100%;
    object-fit:cover
}
.option label:first-child{
    font-weight:700
}
.config-form .farb {
    display: flex;
    flex-direction: row;
    gap:10px
}
.config-form .farb div{
   border:1px solid;
    width:fit-content;
}
.config-form .farb input{
    display:none;
}
.parent-desc{
    padding:20px;
    
}
@media screen and (max-width: 1201px) {
    .img-galarie {
        flex-direction: column-reverse;
    }
    .side-img {
        flex-direction: row;
        width: 100%;
        gap: 5px;
        overflow:hidden !important;
        height:30% ;
        overflow-x:scroll !important
        

    }
     .side-img::-webkit-scrollbar {
        width: 100%;
        height: 4px;
    }
        .side-img img {
            height: 200px !important;
            padding: 5px;
            border-bottom: 2px solid #DDDDDD;
          
        }
    .main_img{
        width:100%;
       height:400px
    }
}
@media screen and (max-width: 993px) {
    .product-info-desc {
        flex-direction: column;
        overflow:hidden
    }
    .img-galarie {
        width: 100%;
        height: 390px;
    }
    .side-img {
        justify-content: center;
        height: 100px ;
    }
        .side-img img {
            border: 1px solid #DDDDDD;
            width: 50px ;
            height: 60px !important;
            border-radius: 10%;
        }
    .main_img {
        object-fit: contain;
        display: block;
        
    }
    .des-prise{
        width:100%;
    }
    .des-prise h2{
        font-size:1.4rem
    }
   .des-prise h6 {
            font-size: 1.2rem
  }
  .info_item i{
      font-size:0.8rem !important
  }
    .info_item span {
        font-size: 16px
    }
}


@media screen and (max-width: 553px) {
    .des-prise{
        flex-direction:column-reverse;
    }

    .configuration{
        width:100%
    }
    .secend-info
    {
        flex-direction:column
    }
}